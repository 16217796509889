:root {
  --primary: #003366;
  --secondary: #006699;
  --accent: #00a3cc;
  --text: #333333;
  --background: #ffffff;
  --card-bg: #f8f9fa;
  --border: #e6e6e6;
  --shadow: rgba(0, 0, 0, 0.1);
  --header-bg: rgba(255, 255, 255, 0.95);
  --footer-bg: #003366;
  --footer-text: #ffffff;
}

[data-theme="dark"] {
  --primary: #0066cc;
  --secondary: #0099ff;
  --accent: #33ccff;
  --text: #e6e6e6;
  --background: #1a1a1a;
  --card-bg: #2a2a2a;
  --border: #444444;
  --shadow: rgba(0, 0, 0, 0.3);
  --header-bg: rgba(26, 26, 26, 0.95);
  --footer-bg: #0d0d0d;
  --footer-text: #e6e6e6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--background);
  color: var(--text);
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header styles */
header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: var(--header-bg);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px var(--shadow);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-text {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary);
  text-decoration: none;
  letter-spacing: 1px;
}

.logo-text span {
  color: var(--accent);
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 30px;
}

nav ul li a {
  text-decoration: none;
  color: var(--text);
  font-weight: 500;
  font-size: 1rem;
  position: relative;
  padding-bottom: 5px;
}

nav ul li a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: var(--accent);
  bottom: 0;
  left: 0;
  transition: width 0.3s;
}

nav ul li a:hover:after {
  width: 100%;
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text);
  cursor: pointer;
  margin-left: 30px;
  display: flex;
  align-items: center;
}

/* Hero section */
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, var(--primary), var(--secondary));
  position: relative;
  overflow: hidden;
}

.hero:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../public/images/cover.jpg') center/cover no-repeat;
  opacity: 0.15;
}

.hero-content {
  position: relative;
  z-index: 1;
  color: white;
  max-width: 650px;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  opacity: 0.9;
}

.cta-button {
  display: inline-block;
  background-color: white;
  color: var(--primary);
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Section styles */
section {
  padding: 100px 0;
  position: relative;
}

section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--primary);
  position: relative;
  display: inline-block;
}

section h2:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background-color: var(--accent);
  bottom: -10px;
  left: 0;
}

section p {
  margin-bottom: 20px;
  font-size: 1.1rem;
}

/* Services section */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.service-card {
  background-color: var(--card-bg);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 10px 30px var(--shadow);
  border: 1px solid var(--border);
  transition: transform 0.3s;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 20px;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: var(--primary);
}

/* About section */
.about {
  background-color: var(--card-bg);
}

.about-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
}

.about-image {
  border-radius: 10px;
  box-shadow: 0 15px 30px var(--shadow);
  overflow: hidden;
}

.about-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* Clients section */
.clients {
  background-color: var(--card-bg);
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.expertise-item {
  text-align: center;
  padding: 30px;
  background-color: var(--card-bg);
  border-radius: 10px;
  box-shadow: 0 10px 30px var(--shadow);
  border: 1px solid var(--border);
}

.expertise-icon {
  font-size: 2.5rem;
  color: var(--accent);
  margin-bottom: 20px;
}

.expertise-item h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: var(--primary);
}

/* Contact section */
.contact-form {
  max-width: 700px;
  margin: 50px auto 0;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid var(--border);
  border-radius: 5px;
  background-color: var(--card-bg);
  color: var(--text);
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 2px rgba(0, 163, 204, 0.2);
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: var(--secondary);
}

/* Footer styles */
footer {
  background-color: var(--footer-bg);
  color: var(--footer-text);
  padding: 60px 0 30px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 50px;
  margin-bottom: 50px;
}

.footer-column h3 {
  font-size: 1.3rem;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.footer-column h3:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: var(--accent);
  bottom: -10px;
  left: 0;
}

.footer-column p {
  margin-bottom: 15px;
}

.footer-links {
  list-style: none;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  text-decoration: none;
  color: var(--footer-text);
  opacity: 0.8;
  transition: opacity 0.3s;
}

.footer-links a:hover {
  opacity: 1;
}

.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--footer-text);
  text-decoration: none;
  transition: background-color 0.3s;
}

.social-links a:hover {
  background-color: var(--accent);
}

.copyright {
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
  opacity: 0.7;
}

/* Parallax effect */
.parallax-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateZ(-1px) scale(2);
}

/* Responsive styles */
@media (max-width: 992px) {
  .hero h1 {
    font-size: 3rem;
  }
  .about-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    padding: 15px 0;
  }
  .logo {
    margin-bottom: 15px;
  }
  nav ul li {
    margin-left: 15px;
    margin-right: 15px;
  }
  .hero h1 {
    font-size: 2.5rem;
  }
  section {
    padding: 70px 0;
  }
}

@media (max-width: 576px) {
  .hero h1 {
    font-size: 2rem;
  }
  nav ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  nav ul li {
    margin: 5px 10px;
  }
  .theme-toggle {
    margin-top: 10px;
  }
}
/* Theme toggle styles */
.theme-toggle-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}

.theme-toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.theme-toggle-label {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
  background-color: var(--border);
  border-radius: 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: hidden;
}

.toggle-circle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/* Sun and Moon icons */
.sun-icon {
  position: absolute;
  right: 6px;
  top: 5px;
  color: #f39c12;
  transition: opacity 0.3s;
  opacity: 1;
}

.moon-icon {
  position: absolute;
  left: 6px;
  top: 5px;
  color: #f5f5f5;
  transition: opacity 0.3s;
  opacity: 0;
}

/* When toggled to dark mode */
.theme-toggle-input:checked + .theme-toggle-label {
  background-color: var(--primary);
}

.theme-toggle-input:checked + .theme-toggle-label .toggle-circle {
  transform: translateX(26px);
  background-color: #2c3e50;
}

.theme-toggle-input:checked + .theme-toggle-label .sun-icon {
  opacity: 0;
}

.theme-toggle-input:checked + .theme-toggle-label .moon-icon {
  opacity: 1;
}

/* Focus styles for accessibility */
.theme-toggle-input:focus + .theme-toggle-label {
  box-shadow: 0 0 0 2px var(--accent);
}

/* Hover effect */
.theme-toggle-label:hover .toggle-circle {
  box-shadow: 0 0 5px 2px var(--shadow);
}